/**
 * Created by user on 15-9-7.
 */
function windowHeight() {
    var documentHeight = $(window).height();
    $('.section-1').css('min-height', documentHeight - 90);
    $('.vAlignWrapper, #mapdiv, .section-1 .second').height(documentHeight);
    // $('.section-2 .first').height(documentHeight - 100);
    $('video').fadeIn();
    //$('.loader').fadeOut();
}

function scrollTo(){
    $('#explore').click(function (e) {
        e.preventDefault();
        var elementoffset =0;
        var stickyHeight = $('.landing-banner').height();
        var deviceW = $(window).width();
        if (deviceW>768) {
            elementoffset = (stickyHeight);
        }else {
            elementoffset = (stickyHeight - 40);
        }
        console.log(elementoffset);
        $("html,body").animate({
            scrollTop: elementoffset
        })

    });
    $('#logoMenu').click(function () {
        $("html,body").animate({
            scrollTop: "0"
        })
    });
}

function hideVideoIOS() {
    var iOS = false,
        p = navigator.platform;
    if( p === 'iPad' || p === 'iPhone' || p === 'iPod' ) {
        iOS = true;
    }
    if (iOS != false) {
        $("video").hide();
    }
}

$(document).ready(function(){
    windowHeight();
    scrollTo();
    hideVideoIOS();

    $('.flexslider').flexslider({
        animation: "slide",
        autoplay: false,
        slideshow: false,
        controlNav: true
    });

    // code for resize flexslider
    $(window).bind('resize', function () {
        setTimeout(function () {
            try {

                var s = $('.flexslider').data('flexslider');
                s.resize();
            }
            catch(e) {}
            
        }, 1000);
    });

    $('.tvc_content_browse_collection_slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    });

    // $('.tvc_content_browse_collection_slider_2').slick({
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 3
    // });

    $('.tvc_content_browse_destination_slider').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.tvc_content_featured_villas').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.tvc_content_our_clients_slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    // slidesToShow: 1,
                    // slidesToScroll: 1,
                    // infinite: false,
                    arrows: false
                }
            }
        ]
    });

    $('.tvc_content_might_collection_slider').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    if($(window).width() <= 481) {
        $('.tvc_wrapper_slides_slick_travel_digest').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    $('.tvc_link_more_banner_destination_2_scroll').on('click', function(e) {
        e.preventDefault(); // prevent hard jump, the default behavior

        var target = $(this).attr("href"); // Set the target as variable

        // perform animated scrolling by getting top-position of target-element and set it as scroll target
        $('html, body').stop().animate({
            scrollTop: $(target).offset().top - 80
        }, 600, function() {
            location.hash = target; //attach the hash (#jumptarget) to the pageurl
        });

        return false;
    });
});

$( window ).resize(function () {
    windowHeight();
});

function setCookieByName(name, value) {
    var now = new Date();
    var time = now.getTime();
    time += 24 * 3600 * 1000;
    now.setTime(time);
    document.cookie = name+"=" + JSON.stringify(value) + "; expires=" + now.toUTCString() + ";";
}

function getCookieByName(name) {
    var cookieValue = new RegExp("" + name + "[^;]+").exec(document.cookie);

    if (cookieValue) {
        return JSON.parse(decodeURI(!!cookieValue ? cookieValue.toString().replace(/^[^=]+./,"") : ""));
    }
    return {};
}

$('.price-sleep-filter-selector').on('change', function() {
    localStorage.setItem('tvcVillasSort', $(this).val());
    // console.log('change: ',$(this).val());
    window.location.replace($(this).val());
    // filterVillas();
});

$('body').on('change', '.filter-selector', function(event) {

    if ( $(this).data("keep") !== undefined ) {
        return
    }
    var value = $(this).val();
    var param = $(this).data("param");
    
    var params = { 
        'countryid': getUrlParameter('countryid'), 
        'state': getUrlParameter('state'), 
        'collection': getUrlParameter('collection'),
        'sort' : getUrlParameter('sort')
    };

    for( var key in params ) {
        if ( param === key ) {
            params[ key ] = value;
        }
    }

    window.location.replace( '?' + Object.keys(params).map(function(key) {
        return key !== 'sort' ? (key + '=' + params[key]) :  params[key]
    }).join('&') );
});

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, '    '));
};